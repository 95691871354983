<template>
  <div id="app" :class="{ 'easter-egg-active': easterEggActive }">
    <!-- Loader with Logo Animation -->
    <div v-if="loading" class="loader">
      <img src="@/assets/logo.webp" class="loader-logo" alt="RBL LLC Logo" />
    </div>

    <!-- Main Content -->
    <div v-else>
      <!-- Header Section with Logo Animation -->
      <header class="header">
        <div class="header-content">
          <div class="logo-container" @click="triggerLogoEasterEgg">
            <img src="@/assets/logo.webp" alt="RBL LLC Logo" class="logo" />
          </div>
          <div class="header-text">
            <h1>RBL, LLC.</h1>
            <p>DEV & CREATE | Rock Bottom Legacy</p>
            <a href="#portfolio" class="cta-button">Explore Our Work</a>
          </div>
        </div>
      </header>

      <!-- Navigation -->
      <nav id="navbar" :class="{ 'scrolled': isScrolled }">
        <ul>
          <li><a href="#portfolio">Portfolio</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#contact">Contact</a></li>
          <li><a href="#" @click.prevent="toggleEasterEgg">🎨</a></li>
        </ul>
      </nav>

      <!-- Portfolio Section -->
      <section id="portfolio" class="portfolio-grid">
        <div class="portfolio-card" 
             v-for="project in projects" 
             :key="project.id" 
             @click="expandProject(project)"
             :style="{ '--rotation': `${Math.random() * 5 - 2.5}deg` }">
          <div class="card-content">
            <div class="browser-header">
              <div class="browser-controls">
                <span class="control red"></span>
                <span class="control yellow"></span>
                <span class="control green"></span>
              </div>
              <div class="browser-url">{{ project.url }}</div>
            </div>
            <div class="browser-content">
              <iframe :src="project.demo" frameborder="0"></iframe>
            </div>
          </div>
          <div class="project-info">
            <h3 class="project-title">{{ project.title }}</h3>
            <p class="project-description">{{ project.description }}</p>
            <div class="project-tags">
              <span class="tag" v-for="tag in project.tags" :key="tag">{{ tag }}</span>
            </div>
          </div>
        </div>
      </section>

      <!-- About Section -->
      <section id="about" class="about-section" :class="{ 'animate': isAboutVisible }">
        <h2>About Us</h2>
        <p>RBL, LLC. is a cutting-edge web development company specializing in creating innovative digital solutions. Our team of expert developers and designers are passionate about delivering high-quality, user-centric websites and applications.</p>
        <h3>Our Skills</h3>
        <div class="skills">
          <span class="skill" 
                v-for="skill in skills" 
                :key="skill"
                @mouseover="highlightSkill"
                @mouseout="unhighlightSkill">
            {{ skill }}
          </span>
        </div>
      </section>

      <!-- Contact Section -->
      <section id="contact" class="contact-section" :class="{ 'animate': isContactVisible }">
        <h2>Get In Touch</h2>
        <form class="contact-form" @submit.prevent="submitForm">
          <input v-model="form.name" type="text" placeholder="Your Name" required>
          <input v-model="form.email" type="email" placeholder="Your Email" required>
          <textarea v-model="form.message" placeholder="Your Message" rows="5" required></textarea>
          <button type="submit">Send Message</button>
        </form>
      </section>

      <!-- Footer Section -->
      <footer class="footer">
        <p>© {{ new Date().getFullYear() }} RBL, LLC. All rights reserved.</p>
        <div class="social-links">
          <a href="#" target="_blank"><i class="fab fa-facebook"></i></a>
          <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
          <a href="#" target="_blank"><i class="fab fa-linkedin"></i></a>
          <a href="#" target="_blank"><i class="fab fa-github"></i></a>
        </div>
      </footer>

      <!-- Easter Egg Animation -->
      <div class="easter-egg" v-if="showEasterEgg">
        <img src="@/assets/logo.webp" class="egg-image" alt="Easter Egg" />
        <p>You found a hidden surprise!</p>
      </div>

      <!-- Expanded Project View -->
      <div class="expanded-view" v-if="expandedProject" @click="closeExpandedView">
        <div class="expanded-content" @click.stop>
          <button class="close-btn" @click="closeExpandedView">Close</button>
          <iframe :src="expandedProject.demo"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      loading: true,
      isScrolled: false,
      isAboutVisible: false,
      isContactVisible: false,
      expandedProject: null,
      easterEggActive: false,
      showEasterEgg: false,
      projects: [
        {
          id: 1,
          title: "The Valley Tremors Band",
          description: "A Modern Responsive Rock Band Website. Containing Custom Music Player & Promotional Page for Shows & Promos.",
          tags: ["Music Player", "Vue3", "JavaScript", "Custom Components", "Photoshop Editing"],
          url: "https://thevalleytremors.com/",
          demo: "https://thevalleytremors.com/"
        },
        {
          id: 2,
          title: "PlateFinder",
          description: "A revolutionary platform for locating and identifying license plates. Hit N` Runs turned to Hit Then Founds. Guess plates isn`t fun when insurance is the one asking.",
          tags: ["Vue3", "Python 3", "API Intergration", "SQL & NoSQL Supported Database Support", "Custom Coded API", "Public Safety"],
          url: "https://platefinder.info/",
          demo: "https://platefinder.info/"
        },
        {
          id: 3,
          title: "The Green Luxe",
          description: "A Beauty Website with Registration, User Portal, Admin Dashboard, and Custom Coded API Components Managing Backend Services." ,
          tags: ["HTML5", "CSS3", "JavaScript", "Python Backend API Integration"],
          url: "https://thegreenluxe.life/",
          demo: "https://TheGreenLuxe.life/"
        },
        {
          id: 4,
          title: "Noz Kings",
          description: "N/A",
          tags: ["N/A", "N/A", "JavaScript", "N/A"],
          url: "https://example.com/",
          demo: "https://example.com/"
        },
      ],
      skills: [
        "Web Development",
        "UI/UX Design",
        "Mobile App Development",
        "E-commerce Solutions",
        "API Integration",
        "Cloud Services"
      ],
      form: {
        name: '',
        email: '',
        message: ''
      }
    };
  },
  mounted() {
    this.initLogoLoader();
    this.initScrollEffect();
    this.initSectionAnimations();
    this.initKonamiCode();
    this.triggerEasterEgg();
  },
  methods: {
    initLogoLoader() {
      setTimeout(() => {
        this.loading = false;
        gsap.from('.logo-container', { 
          opacity: 0, 
          scale: 0.5, 
          duration: 2, 
          ease: 'elastic.out(1, 0.3)',
          onComplete: () => {
            gsap.to('.logo-container', { 
              yoyo: true, 
              repeat: -1, 
              duration: 2, 
              y: -20, 
              ease: 'power1.inOut' 
            });
          }
        });
      }, 3000);
    },
    initScrollEffect() {
      window.addEventListener('scroll', () => {
        this.isScrolled = window.scrollY > 100;
      });
    },
    initSectionAnimations() {
      ScrollTrigger.batch('.portfolio-card', {
        onEnter: (elements) => gsap.from(elements, { opacity: 0, y: 50, stagger: 0.15 }),
        start: 'top 80%'
      });

      ScrollTrigger.create({
        trigger: '#about',
        start: 'top 80%',
        onEnter: () => this.isAboutVisible = true
      });

      ScrollTrigger.create({
        trigger: '#contact',
        start: 'top 80%',
        onEnter: () => this.isContactVisible = true
      });
    },
    expandProject(project) {
      this.expandedProject = project;
    },
    closeExpandedView() {
      this.expandedProject = null;
    },
    submitForm() {
      console.log('Form submitted:', this.form);
      // Add your form submission logic here
      this.form = { name: '', email: '', message: '' };
    },
    toggleEasterEgg() {
      this.easterEggActive = !this.easterEggActive;
    },
    triggerLogoEasterEgg() {
      gsap.to('.logo', { rotationY: '+=360', duration: 1 });
    },
    highlightSkill(event) {
      gsap.to(event.target, { scale: 1.1, rotation: 5, duration: 0.3 });
    },
    unhighlightSkill(event) {
      gsap.to(event.target, { scale: 1, rotation: 0, duration: 0.3 });
    },
    initKonamiCode() {
      const konamiCode = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
      let konamiIndex = 0;

      document.addEventListener('keydown', (e) => {
        if (e.key === konamiCode[konamiIndex]) {
          konamiIndex++;
          if (konamiIndex === konamiCode.length) {
            this.activateKonamiEasterEgg();
            konamiIndex = 0;
          }
        } else {
          konamiIndex = 0;
        }
      });
    },
    activateKonamiEasterEgg() {
      alert('Konami Code Activated! You found a secret easter egg!');
      gsap.to('body', { rotation: 360, duration: 1, onComplete: () => gsap.set('body', { rotation: 0 }) });
    },
    triggerEasterEgg() {
      const secretSpot = document.querySelector('.footer');
      secretSpot.addEventListener('dblclick', () => {
        this.showEasterEgg = true;
        setTimeout(() => {
          this.showEasterEgg = false;
        }, 5000);
      });
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --accent-color: #e74c3c;
  --text-color: #333;
  --bg-color: #f4f4f4;
  --card-bg: #fff;
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  color: var(--text-color);
  line-height: 1.6;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.loader-logo {
  width: 200px;
  height: 200px;
  animation: spin 4s infinite ease-in-out;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.header {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: #fff;
  padding: 3rem 0;
  position: relative;
  overflow: hidden;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  width: 300px;
  height: 300px;
  position: relative;
  cursor: pointer;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header-text {
  flex: 1;
  padding-left: 2rem;
}

.header h1 {
  font-size: 3rem;
  margin: 0;
  font-weight: 700;
}

.header p {
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.cta-button {
  display: inline-block;
  background-color: var(--accent-color);
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 1rem;
}

.cta-button:hover {
  background-color: #c0392b;
  transform: translateY(-3px);
}

nav {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 100;
  transition: background-color 0.3s ease;
}

nav.scrolled {
  background-color: var(--secondary-color);
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 1rem;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  padding: 1rem 0;
  display: inline-block;
  position: relative;
  transition: color 0.3s ease;
}

nav ul li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--accent-color);
  transition: width 0.3s ease;
}

nav ul li a:hover::after {
  width: 100%;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.portfolio-card {
  background-color: var(--card-bg);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  transform: rotate(var(--rotation));
}

.portfolio-card:hover {
  transform: translateY(-10px) rotate(calc(var(--rotation) * 2));
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.card-content {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.portfolio-card:hover .card-content {
  transform: rotateY(180deg);
}

.browser-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #e8e8e8;
  border-bottom: 1px solid #ddd;
}

.browser-controls {
  display: flex;
  gap: 5px;
}

.browser-controls .control {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.control.red { background-color: #ff5f57; }
.control.yellow { background-color: #ffbd2e; }
.control.green { background-color: #28c840; }

.browser-url {
  flex-grow: 1;
  margin: 0 1rem;
  padding: 0.25rem 0.5rem;
  background-color: #fff;
  border-radius: 15px;
  font-size: 0.8rem;
  color: #555;
}

.browser-content {
  height: 200px;
  overflow: hidden;
}

.browser-content iframe {
  width: 100%;
  height: 100%;
  border: none;
  transition: transform 0.3s ease;
}

.portfolio-card:hover .browser-content iframe {
  transform: scale(1.1);
}

.project-info {
  padding: 1rem;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  color: #fff;
}

.project-title {
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.project-description {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

.tag {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 0.25rem 0.5rem;
  border-radius: 15px;
  font-size: 0.8rem;
}

.about-section,
.contact-section {
  max-width: 800px;
  margin: 4rem auto;
  padding: 2rem;
  background-color: var(--card-bg);
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.about-section.animate,
.contact-section.animate {
  opacity: 1;
  transform: translateY(0);
}

.skills {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.skill {
  background-color: var(--primary-color);
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.skill:hover {
  background-color: var(--accent-color);
}

.contact-form {
  display: grid;
  gap: 1rem;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #ddd;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  transition: border-color 0.3s ease, transform 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  transform: scale(1.02);
}

.contact-form button {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-form button:hover {
  background-color: var(--accent-color);
  transform: scale(1.05);
}

.footer {
  background-color: var(--secondary-color);
  color: #fff;
  text-align: center;
  padding: 2rem 0;
  margin-top: 4rem;
}

.social-links {
  margin-top: 1rem;
}

.social-links a {
  color: #fff;
  font-size: 1.5rem;
  margin: 0 0.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-links a:hover {
  color: var(--accent-color);
  transform: scale(1.2);
}

.expanded-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.expanded-content {
  background-color: #fff;
  width: 90%;
  height: 90%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.expanded-content iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--primary-color);
  border: none;
  padding: 10px 20px;
  color: #fff;
  font-size: 1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.close-btn:hover {
  background-color: var(--accent-color);
  transform: scale(1.1);
}

.easter-egg {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: var(--accent-color);
  padding: 20px;
  border-radius: 50%;
  text-align: center;
  z-index: 9999;
  animation: popIn 0.5s ease-out;
}

@keyframes popIn {
  0% { transform: scale(0); }
  80% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.egg-image {
  width: 50px;
  height: 50px;
}

.easter-egg-active {
  animation: colorChange 10s infinite;
}

@keyframes colorChange {
  0% { filter: hue-rotate(0deg); }
  100% { filter: hue-rotate(360deg); }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    text-align: center;
  }

  .logo-container {
    width: 200px;
    height: 200px;
    margin-bottom: 2rem;
  }

  .header-text {
    padding-left: 0;
  }

  .header h1 {
    font-size: 2.5rem;
  }

  .header p {
    font-size: 1rem;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 0.5rem 0;
  }

  .portfolio-grid {
    grid-template-columns: 1fr;
  }
}

/* Particle Effect for Logo */
.logo-container::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: var(--accent-color);
  border-radius: 50%;
  box-shadow: 0 0 10px 2px var(--accent-color);
  animation: particleEffect 2s infinite;
}

@keyframes particleEffect {
  0% { transform: translate(-50%, -50%) scale(1); opacity: 1; }
  100% { transform: translate(-50%, -50%) scale(3); opacity: 0; }
}

/* Typing Effect for CTA Button */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

.cta-button {
  position: relative;
  overflow: hidden;
}

.cta-button::before {
  content: 'Explore Our Work';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: var(--accent-color);
  border-radius: 30px;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: typing 2s steps(40, end) forwards;
}

/* Hidden Konami Code Easter Egg */
body::after {
  content: '↑↑↓↓←→←→BA';
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  color: var(--secondary-color);
  opacity: 0.1;
}
</style>